import { render, staticRenderFns } from "./templateMail.vue?vue&type=template&id=71f0bda9&scoped=true&"
import script from "./templateMail.vue?vue&type=script&lang=js&"
export * from "./templateMail.vue?vue&type=script&lang=js&"
import style0 from "./templateMail.vue?vue&type=style&index=0&id=71f0bda9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f0bda9",
  null
  
)

export default component.exports