<template>
  <div class="template-mail-cdg">
    <!-- TEMPLATE MAILS FRAIS -->
    <div class="row box-mail-frais mb-1">
      <div class="col-2 ">
        <div class="sub-box">
          <div class="title-mail-cdg">Sociéte</div>
          <b-form-checkbox
            v-model="checkAllFiliale"
            @change="checkAllFilialeMethod"
            ><span class="check-title-mail-cdg">Tous</span></b-form-checkbox
          >
          <div class="content-client">
            <div
              class="d-flex"
              v-for="(client, indexClient) in dataToUse.clients"
              :key="'client' + indexClient"
            >
              <div class="d-flex">
                <div
                  @click.prevent.stop="checkFiliale(client)"
                  class="check-icon-mail-cdg"
                  :class="{ 'checked-icon-mail-cdg': client.check }"
                >
                  <font-awesome-icon
                    icon="check"
                    v-if="client.check"
                    class="mb-1"
                  />
                </div>
                <span
                  @click.prevent.stop="indexM = indexClient"
                  class="check-title-mail-cdg"
                  :class="{
                    'check-title-mail-cdg-active': indexClient == indexM
                  }"
                  >{{ client.name_client }}</span
                >
              </div>
              <font-awesome-icon
                class="ml-2 icon-selected-filiale"
                icon="arrow-right"
                v-if="indexClient == indexM"
              />
            </div>
          </div>
          <div class="action-client">
            <b-button
              class="button-valide-style mr-4"
              @click.prevent.stop="sendMail"
              :disabled="computedDisabledSend"
            >
              <span>
                Envoyer
                <div class="loading ml-2"></div>
              </span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="col-10">
        <div class="sub-box">
          <div class="action-client mb-1">
            <!-- <b-button
              class="button-valide-style mr-4"
              @click.prevent.stop="confirmFilialeMail()"
            >
              <span>
                Confirmer
                <div class="loading ml-2"></div>
              </span>
            </b-button> -->
            <span class="lot-title"> {{ dataToUse.lot }}</span>
          </div>
          <div class="editor-vendeur-content">
            <step-content :dataToUse="dataToUse.clients[indexM]" />
          </div>
        </div>
      </div>
    </div>
    <div class="actionModelMailCdg d-flex justify-content-center">
      <b-button
        class="button-valide-style mr-4"
        type="submit"
        v-if="indexM < dataToUse.clients.length && indexM > 0"
        @click="previousMail()"
      >
        <span>
          Précédent
          <div class="loading ml-2"></div>
        </span>
      </b-button>
      <span
        v-for="(i, indexlot) in dataToUse.clients"
        :key="'lotIndex' + indexlot"
        class="circleStep"
        :class="{ circleStepActive: indexlot == indexM }"
      ></span>
      <b-button
        class="button-valide-style ml-4"
        type="submit"
        v-if="indexM < dataToUse.clients.length - 1"
        @click="nextMail()"
      >
        <span>
          Suivant
          <div class="loading ml-2"></div>
        </span>
      </b-button>
    </div>
    <custom-swal
      :dataToShow="resultMail"
      v-if="showResultSwal"
      @closeResultSwal="showResultSwal = false"
    />
    <!-- END TEMPLATE MAILS FRAIS -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    changeVendeurIndex: { required: true }
  },
  data() {
    return {
      indexM: 0,
      checkAllFiliale: true,
      resultMail: null,
      showResultSwal: false,
      loaderSendMail: false
    };
  },
  computed: {
    ...mapGetters(['getLoaderSendMailCdg']),
    computedDisabledSend() {
      return (
        this.loaderSendMail ||
        (this.dataToUse != null &&
          this.dataToUse.clients.filter(i => i.check == true).length == 0)
      );
    }
  },
  methods: {
    ...mapActions(['sendMailCdg']),
    previousMail() {
      this.indexM = this.indexM - 1;
    },
    nextMail() {
      this.indexM = this.indexM + 1;
    },
    confirmFilialeMail() {
      this.dataToUse.clients[this.indexM].check = true;
      this.verifCheckAll();
      if (this.indexM < this.dataToUse.clients.length - 1) {
        this.indexM = this.indexM + 1;
      }
    },
    async sendMail() {
      this.loaderSendMail = true;
      if (
        this.dataToUse &&
        this.dataToUse.clients &&
        this.dataToUse.clients.filter(item => item.check == true).length > 0
      ) {
        let payload = new FormData();
        this.dataToUse.clients.forEach((element, i) => {
          if (element.check == true) {
            payload.append(
              'mail_to_send[' + i + '][send_to_vendeur]',
              element.send_to_vendeur
            );
            payload.append('mail_to_send[' + i + '][lot]', this.dataToUse.lot);
            payload.append(
              'mail_to_send[' + i + '][lot_id]',
              this.dataToUse.lot_id
            );
            payload.append('mail_to_send[' + i + '][html]', element.html);
            //client property
            payload.append(
              'mail_to_send[' + i + '][client][id]',
              element.client.id
            );
            payload.append(
              'mail_to_send[' + i + '][client][name]',
              element.client.name
            );
            payload.append(
              'mail_to_send[' + i + '][client][email]',
              element.client.email
            );
            element.client.extra_mails.forEach((extra, iExtra) => {
              payload.append(
                'mail_to_send[' + i + '][client][extra_mails][' + iExtra + ']',
                extra
              );
            });
            //end proprty client
            //vendeur property
            payload.append(
              'mail_to_send[' + i + '][vendeur][id]',
              element.vendeur.id
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][name]',
              element.vendeur.name
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][email]',
              element.vendeur.email
            );
            element.vendeur.extra_mails.forEach((extra, iExtra) => {
              payload.append(
                'mail_to_send[' + i + '][vendeur][extra_mails][' + iExtra + ']',
                extra
              );
            });
            //end proprty vendeur
            //facture ids
            element.ids_facture.forEach((id, iId) => {
              payload.append(
                'mail_to_send[' + i + '][ids_facture][' + iId + ']',
                id
              );
            });
          }
        });

        const response = await this.sendMailCdg(payload);
        if (response.succes) {
          this.$emit('eventMailSend');
          // this.dataToUse.mailSend = true;
          this.resultMail = response.data;
          this.showResultSwal = true;
          this.loaderSendMail = false;
        } else {
          this.loaderSendMail = false;
        }
      }
    },
    checkAllFilialeMethod() {
      this.dataToUse.clients.map(i => (i.check = this.checkAllFiliale));
    },
    checkFiliale(client) {
      client.check = !client.check;
      this.verifCheckAll();
    },
    verifCheckAll() {
      if (
        this.dataToUse.clients.filter(i => i.check == true).length ==
        this.dataToUse.clients.length
      ) {
        this.checkAllFiliale = true;
      } else {
        this.checkAllFiliale = false;
      }
    }
  },
  components: {
    stepContent: () => import('./stepContent.vue'),
    customSwal: () => import('./customSwal.vue')
  },
  watch: {
    changeVendeurIndex: function() {
      this.indexM = 0;
      this.checkAllFiliale = true;
    }
  }
};
</script>

<style scoped lang="scss">
.template-mail-cdg {
  height: calc(100vh - 120px);
  .box-mail-frais {
    height: calc(100vh - 105px);
    .sub-box {
      height: calc(100vh - 103px);
      background-color: #f6f5fb;
      border: 1px solid #e0ddf0;
      margin-bottom: 8px;
      border-radius: 10px;
      padding: 6px;
      .content-client {
        height: calc(100vh - 210px);
      }
      .action-client {
        text-align-last: center;
        height: 26px;
        .lot-title {
          position: absolute;
          right: 26px;
          font-weight: 700;
          margin-top: 8px;
          font-size: 20px;
          color: #4d4bac;
        }
      }
      .check-title-mail-cdg {
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;
      }
      .check-title-mail-cdg-active {
        font-size: 13px;
        font-weight: 800;
      }
      .check-icon-mail-cdg {
        height: 15px;
        width: 15px;
        border-radius: 4px;
        border: 1px solid rgb(0 0 0 / 29%);
        margin-right: 10px;
        background-color: #fff;
      }
      .checked-icon-mail-cdg {
        background: #4d4bac;
        color: #fff;
        text-align: center;
        font-size: 9px;
      }
      .icon-selected-filiale {
        color: #030085;
      }
    }
  }
  .editor-vendeur-content {
    height: calc(100vh - 120px);
  }
  .actionModelMailCdg {
  }
  .title-mail-cdg {
    color: #4d4bac;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
